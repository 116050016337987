<template>
    <div>
        <div class="flex flex-space-between border-bottom-low-contrast pbottom-xsmall mbottom-medium">
            <h3 class="mbottom-none">
                {{ $t("admin_user_access_easy_access_title") }}
            </h3>
            <icon
                v-tooltip.bottom="$t('admin_user_access_easy_access_tooltip')"
                class="clickable"
                name="help-circle"
                @click.native="openHelpModal"
            />
        </div>
        <pu-skeleton
            :loading="accessCode === undefined"
            height="20rem"
        >
            <div class="description">
                <p>{{ $t("admin_user_access_easy_access_description") }}</p>
                <select-button-group
                    class="mleft-small"
                    :value="accessCode !== null"
                    :options="selectOptions"
                    @input="toggleFeature"
                />
            </div>
            <p class="meta">
                {{ $t("admin_user_access_easy_access_meta", [intranet.displayName]) }}
            </p>
            <transition name="fade">
                <div v-if="accessCode">
                    <div class="mbottom-medium">
                        <div class="form-group">
                            <label for="link">{{ $t("admin_user_access_easy_access_link_label") }}</label>
                            <div class="flex flex-center">
                                <input
                                    id="link"
                                    class="ellipsis"
                                    :value="accessLink"
                                    type="text"
                                    disabled
                                />
                                <app-button
                                    v-clipboard="accessLink"
                                    v-clipboard:success="clipboardSuccessHandler"
                                    v-tooltip.bottom="$t('copy_to_clipboard')"
                                    class="copy-button mleft-xsmall"
                                    type="light"
                                    fab
                                    icon="copy"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="code">{{ $t("admin_user_access_easy_access_code_label") }}</label>
                            <div class="flex flex-center">
                                <input
                                    id="code"
                                    class="ellipsis"
                                    :value="publicAccessCode"
                                    type="text"
                                    disabled
                                />
                                <app-button
                                    v-clipboard="publicAccessCode"
                                    v-clipboard:success="clipboardSuccessHandler"
                                    v-tooltip.bottom="$t('copy_to_clipboard')"
                                    class="copy-button mleft-xsmall"
                                    type="light"
                                    fab
                                    icon="copy"
                                />
                            </div>
                        </div>
                    </div>
                    <p>{{ $t("admin_user_access_easy_access_new_link_description") }}</p>
                    <app-button
                        :busy="loadingAccessCode"
                        type="light"
                        @click="openGenerateNewLinkModal"
                    >
                        {{ $t("admin_user_access_easy_access_new_link_label") }}
                    </app-button>
                </div>
            </transition>
        </pu-skeleton>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET } from "@/store/intranet/getters";
import { CodeBasedIntranetAccessService } from "@/services/intranet-access";
import { codeBasedIntranetAccessService } from "@/services";
import SelectButtonGroup from "@/components/SelectButtonGroup";

export default {
    name: "CodeBasedIntranetAccess",
    components: { SelectButtonGroup },
    data() {
        return {
            accessCode: undefined,
            loadingAccessCode: true,
            selectOptions: {
                [this.$t("off")]: false,
                [this.$t("on")]: true,
            },
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
        }),
        accessLink() {
            return CodeBasedIntranetAccessService.buildJoinUrl({
                intranetUid: this.intranet.uid,
                accessCode: this.accessCode,
            });
        },
        publicAccessCode() {
            return CodeBasedIntranetAccessService.buildPublicAccessCode({
                intranetUid: this.intranet.uid,
                accessCode: this.accessCode,
            });
        },
    },
    async mounted() {
        try {
            const credentials = await codeBasedIntranetAccessService.getIntranetAccessCredentials(this.intranet.uid);
            this.accessCode = credentials.accessCode;
        } finally {
            this.loadingAccessCode = false;
        }
    },
    methods: {
        async enableCodeBasedAccess() {
            this.loadingAccessCode = true;
            try {
                const credentials = await codeBasedIntranetAccessService.enableCodeBasedAccess(this.intranet.uid);
                this.accessCode = credentials.accessCode;
            } finally {
                this.loadingAccessCode = false;
            }
        },
        async disableCodeBasedAccess() {
            this.accessCode = null;
            await codeBasedIntranetAccessService.disableCodeBasedAccess(this.intranet.uid);
        },
        toggleFeature(enable) {
            this.$modal.show("confirm-modal", {
                title: this.$t(
                    enable
                        ? "admin_user_access_easy_access_activate_title"
                        : "admin_user_access_easy_access_deactivate_title"
                ),
                text: this.$t(
                    enable
                        ? "admin_user_access_easy_access_activate_description"
                        : "admin_user_access_easy_access_deactivate_description",
                    [this.intranet.displayName],
                ),
                warning: !enable,
                confirmText: enable ? this.$t("confirm") : this.$t("deactivate"),
                callback: (confirmed) => {
                    if (confirmed) {
                        if (enable) {
                            this.enableCodeBasedAccess();
                        } else {
                            this.disableCodeBasedAccess();
                        }
                    }
                }
            });
        },
        openHelpModal() {
            this.$modal.show("confirm-modal", {
                title: this.$t("admin_user_access_easy_access_info_title"),
                text: this.$t("admin_user_access_easy_access_info_description"),
            });
        },
        clipboardSuccessHandler() {
            this.$notify({
                group: "app",
                type: "success",
                text: this.$t("copied_clipboard"),
            });
        },
        openGenerateNewLinkModal() {
            this.$modal.show("confirm-modal", {
                title: this.$t("admin_user_access_easy_access_new_link_label"),
                text: this.$t("admin_user_access_easy_access_new_link_description"),
                confirmText: this.$t("confirm"),
                callback: confirmed => {
                    if (confirmed) {
                        this.enableCodeBasedAccess();
                    }
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.description {
    display: flex;
    align-items: flex-start;
}

.copy-button {
    padding: $spacing-xsmall !important;
}

.loading-wrapper {
    min-height: 20rem;
}
</style>
