<template>
    <div class="user-access">
        <div class="mbottom-large">
            <h2>{{ $t("admin_user_access_title") }}</h2>
            <span>{{ $t("admin_user_access_description") }}</span>
        </div>
        <domain-based-intranet-access class="mbottom-large" />
        <code-based-intranet-access />
    </div>
</template>

<script>
import DomainBasedIntranetAccess from "@/views/intranet/admin/user-access/DomainBasedIntranetAccess";
import CodeBasedIntranetAccess from "@/views/intranet/admin/user-access/CodeBasedIntranetAccess";

export default {
    name: "IntranetAccess",
    components: { CodeBasedIntranetAccess, DomainBasedIntranetAccess },
};
</script>

<style scoped>
.user-access {
    max-width: 45rem;
}
</style>
