<template>
    <div class="form-group">
        <h3 class="border-bottom-low-contrast pbottom-xsmall">
            {{ $t("domain_based_access") }}
        </h3>
        <p>
            {{ $t("domain_based_access_description") }}
        </p>
        <span class="input-wrapper">
            <label
                for="new-domain"
                class="input-prefix"
            >example@</label>
            <input
                id="new-domain"
                v-model="newDomain"
                autocomplete="off"
                type="text"
                placeholder="mycompany.com"
                @keypress.enter="addDomain"
            />
            <app-button
                :disabled="!isNewDomainValid || isMaximumReached || isNewDomainDuplicate"
                class="link"
                icon="plus-circle"
                @click="addDomain"
            />
        </span>
        <div class="errors">
            <span
                v-if="isMaximumReached"
                class="meta"
            >{{ $t("domain_based_access_maximum_reached") }}</span>
            <span
                v-if="!isNewDomainValid && newDomain"
                class="meta"
            >{{ $t("domain_based_access_domain_seems_invalid") }}</span>
            <span
                v-if="isNewDomainDuplicate"
                class="meta"
            >{{ $t("domain_based_access_domain_already_exists") }}</span>
        </div>
        <ul class="domain-list">
            <transition-group name="fade">
                <li
                    v-for="domain in domains"
                    :key="domain"
                >
                    {{ domain }}
                    <app-button
                        type="link"
                        icon="x"
                        @click="removeDomain(domain)"
                    />
                </li>
            </transition-group>
        </ul>
        <transition name="fade">
            <div v-if="changed">
                <app-button
                    :busy="busy"
                    type="primary"
                    @click="save"
                >
                    {{ $t("save") }}
                </app-button>
                <app-button
                    :disabled="busy"
                    type="link"
                    @click="reset"
                >
                    {{ $t("cancel") }}
                </app-button>
            </div>
        </transition>
    </div>
</template>

<script>
import Vue from "vue";
import {
    mapActions,
    mapGetters,
} from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET } from "@/store/intranet/getters";
import { UPDATE_DOMAIN_INTRANET_ACCESS } from "@/store/intranet/actions";
import { getGlobalConfiguration } from "@/global-config";

export default Vue.extend({
    name: "DomainBasedIntranetAccess",
    components: {},
    data() {
        return {
            domains: [],
            newDomain: "",
            changed: false,
            busy: false,
        };
    },
    computed: {
        ...mapGetters(INTRANET_MODULE_NAME, [INTRANET]),
        isNewDomainValid() {
            // should reject most invalid domains, secure validation is handled via joi in the backend
            return /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(this.newDomain);
        },
        isMaximumReached() {
            const MAX_DOMAINS = getGlobalConfiguration().domain_based_intranet_access_max_domains;
            return this.domains.length >= MAX_DOMAINS;
        },
        isNewDomainDuplicate() {
            return this.domains.some(d => d === this.newDomain);
        },
    },
    methods: {
        ...mapActions({ updateDomainIntranetAccess: INTRANET_MODULE_NAME + UPDATE_DOMAIN_INTRANET_ACCESS }),
        async save() {
            try {
                if (!this.changed) {
                    return;
                }
                this.busy = true;
                await this.updateDomainIntranetAccess(this.domains);
                this.changed = false;
            } catch {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("domain_based_access_domain_rejected")
                });
            } finally {
                this.busy = false;
            }
        },
        addDomain() {
            if (this.isNewDomainValid && !this.isMaximumReached && !this.isNewDomainDuplicate) {
                this.domains.push(this.newDomain);
                this.newDomain = "";
                this.changed = true;
            }
        },
        removeDomain(domain) {
            this.domains.splice(this.domains.indexOf(domain), 1);
            this.changed = true;
        },
        reset() {
            this.newDomain = "";
            this.changed = false;
            this.initDomains();
        },
        initDomains() {
            const flagConfiguration = this.intranet.flags["domain-based-intranet-access"];
            if (!(flagConfiguration && flagConfiguration.config && flagConfiguration.config.domains)) {
                this.domains = [];
                return;
            }
            this.domains = [...flagConfiguration.config.domains];
        }
    },
    created() {
        this.initDomains();
    }
});
</script>

<style lang="scss" scoped>
.domain-list {
    width: 100%;

    li {
        display: flex;
        border-bottom: 1px solid var(--lowest-contrast, #{$off-white});
        padding: 0.75rem 0;
        align-items: center;
        justify-content: space-between;

        &:last-child {
            border-bottom: none;
        }
    }
}

.input-wrapper {
    display: flex;
    align-items: stretch;
    margin-bottom: $spacing-small;

    .input-prefix {
        border-radius: 3px 0 0 3px;
        background: $low-contrast;
        padding: 0.75rem;
        user-select: none;
        opacity: 1;
        margin: 0;
    }

    input {
        border-radius: 0 3px 3px 0;
    }
}

.errors {
    display: flex;
    flex-direction: column;
    span {
        color: $warning;
    }
}
</style>
